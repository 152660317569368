<template>
    <div class="preview">
        <app-nav />
        <div v-if="Object.keys(recorder).length" class="body">
            <div
                class="progress"
                :style="`--value: ${uploadingInfo.progress};`"
            ></div>
            <div class="main">
                <div ref="viewport" class="preview-wrapper">
                    <div class="video-actions">
                        <div class="form-element">
                            <div class="label">Title</div>
                            <input type="text" v-model="title" />
                        </div>
                        <div class="right">
                            <w-button @click="download" class="button">
                                <icon
                                    :icon="icons.downloadForOffline"
                                    height="18"
                                />
                                Download
                            </w-button>
                            <w-button
                                class="save"
                                @click="_upload"
                                :disabled="!!uploadingInfo.status"
                            >
                                <icon
                                    :icon="icons.baselinePlaylistAdd"
                                    height="20"
                                />
                                Save
                                <span class="status">{{
                                    uploadingInfo.status
                                }}</span>
                            </w-button>
                        </div>
                    </div>
                    <video ref="video" mute controls></video>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
"use strict"

!(function () {
    var t = (window.driftt = window.drift = window.driftt || [])
    if (!t.init) {
        if (t.invoked)
            return void (
                window.console &&
                console.error &&
                console.error("Drift snippet included twice.")
            );
        (t.invoked = !0),
        (t.methods = [
            "identify",
            "config",
            "track",
            "reset",
            "debug",
            "show",
            "ping",
            "page",
            "hide",
            "off",
            "on",
        ]),
        (t.factory = function (e) {
            return function () {
                var n = Array.prototype.slice.call(arguments)
                return n.unshift(e), t.push(n), t
            }
        }),
        t.methods.forEach(function (e) {
            t[e] = t.factory(e)
        }),
        (t.load = function (t) {
            var e = 3e5,
                n = Math.ceil(new Date() / e) * e,
                o = document.createElement("script");
            (o.type = "text/javascript"),
            (o.async = !0),
            (o.crossorigin = "anonymous"),
            (o.src =
                        "https://js.driftt.com/include/" + n + "/" + t + ".js")
            var i = document.getElementsByTagName("script")[0]
            i.parentNode.insertBefore(o, i)
        })
    }
})()
// eslint-disable-next-line no-undef
drift.SNIPPET_VERSION = "0.3.1"
// eslint-disable-next-line no-undef
drift.load("s89ax2updgr7")

import { invokeSaveAsDialog, getSeekableBlob } from "recordrtc"
import wButton from "@/components/wButton"
import { Icon } from "@iconify/vue"
import roundArrowBackIosNew from "@iconify-icons/ic/round-arrow-back-ios-new"
import roundHelpOutline from "@iconify-icons/ic/round-help-outline"
import cloudUpload from "@iconify-icons/ic/baseline-cloud-upload"
import baselinePlaylistAdd from "@iconify-icons/ic/baseline-playlist-add"
import downloadForOffline from "@iconify-icons/ic/baseline-download"
import baselineLogin from "@iconify-icons/ic/baseline-login"
import videoPlusOutline from "@iconify-icons/mdi/video-plus-outline"
import { mapActions, mapState } from "vuex"
import { getAnalytics, logEvent } from "firebase/analytics"

const analytics = getAnalytics()

import {
    getAuth,
    signInWithPopup,
    GoogleAuthProvider,
    onAuthStateChanged,
} from "firebase/auth"
import AppNav from "../components/AppNav.vue"

export default {
    name: "Preview",
    components: {
        wButton,
        Icon,
        AppNav,
    },
    data() {
        return {
            icons: {
                roundArrowBackIosNew,
                roundHelpOutline,
                cloudUpload,
                downloadForOffline,
                baselinePlaylistAdd,
                baselineLogin,
                videoPlusOutline,
            },
            title: "My video",
            videoUrl: "",
            currentUser: null,
        }
    },
    computed: {
        ...mapState(["uploadingInfo", "recorder"]),
    },
    mounted() {
        if (!this.recorder.getBlob) this.$router.push("/record")
        onAuthStateChanged(getAuth(), (user) => {
            this.currentUser = user
        })
        requestAnimationFrame(this.setVideoUrl)
    },
    beforeUnmount() {},
    methods: {
        ...mapActions(["upload"]),
        async _upload() {
            logEvent(analytics, "upload")
            if (!this.currentUser) await new Promise(this.signin)
            this.upload(this.title)
        },
        signin(resolve) {
            signInWithPopup(getAuth(), new GoogleAuthProvider())
                .catch(() => {})
                .then(resolve)
        },
        async setVideoUrl() {
            if (!this.recorder.getBlob) return
            getSeekableBlob(this.recorder.getBlob(), (blob) => {
                this.$refs.video.src = URL.createObjectURL(blob)
            })
        },
        download() {
            logEvent(analytics, "dowload")
            getSeekableBlob(this.recorder.getBlob(), (blob) => {
                invokeSaveAsDialog(blob, this.title + ".webm")
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.preview {
    -webkit-font-smoothing: antialiased;
    background: #101114;
    font-size: 15px;
    margin: 0;
    overflow: hidden;
    color: white;
    display: flex;
    flex-direction: column;

    .body {
        max-width: 1200px;
    }

    .main {
        display: flex;
        justify-content: center;
        padding-bottom: 36px;
        @media only screen and (max-width: 880px) {
            flex-direction: column;
        }

        .preview-wrapper {
            flex: 1;
            padding: 0 12px;
            .video-actions {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
            }
            video {
                width: 100%;
                max-height: calc(100vh - 100px);
                background: black;
                margin-top: 8px;
            }
        }

        aside {
            min-width: 360px;
            padding: 0 12px;

            @media only screen and (max-width: 880px) {
                min-width: auto;
                padding: 36px 12px;
            }
        }

        .form-element {
            display: flex;
            align-items: center;

            .label {
                font-weight: bold;
                padding-bottom: 4px;
            }

            input[type="text"] {
                width: 100%;
                height: 36px;
                padding: 0 8px;
                border-radius: 6px;
                background: #161616;
                border: 1px solid #32353e;
                color: white;
                font-size: 16px;
                margin-left: 8px;
                &:not(:focus) {
                    opacity: 0.6;
                }
            }
        }

        .button {
            color: white;
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            border-radius: 6px;
            gap: 6px;
            font-size: 14px;
            background: transparent;
            padding: 0;
            margin-left: 16px;
            // flex-direction: row-reverse;
            .status {
                flex: 1;
                text-align: right;
            }
        }
    }
}

.back-button {
    padding-left: 4px;
}

.progress {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    background: #d4d9e2;
    height: 0;
    &::before {
        content: "";
        position: absolute;
        left: 0;
        width: calc(var(--value) * 1%);
        height: 4px;
        background: #ff0000;
        transition: all 0.12s ease;
    }
}
</style>
